import React from 'react';
import moment from 'moment-timezone';
import ListingInactiveInfo from '../../components/listing-inactive';
import SvgCalendarBlock from 'src/assets/svg/SvgCalendarBlock.svg';

const RenderInactive = ({ spot, useState }) => {
    return (
        <div className="dis-info">
            <span className="dis-cal-ico">
                <SvgCalendarBlock />
            </span>
            <div className="dis-text">
                <p className="title snif-semibold mb-1">
                    {spot.turnBackOnAt ? 'This spot is taking a break' : 'This spot is not available'}
                </p>
                {spot.turnBackOnAt ? (
                    <p className="snif-p mb-0">
                        {'The host has blocked all bookings until '}
                        <span className="snif-medium">{moment(spot.turnBackOnAt).format('MMMM D, YYYY')}</span>.
                    </p>
                ) : (
                    <p className="snif-p mb-0">The host has made this spot indefinitely unavailable for bookings.</p>
                )}
            </div>
            {!spot.turnBackOnAt && (
                <span className="dis-more-info">
                    <ListingInactiveInfo {...{ spot, useState }} />
                </span>
            )}
        </div>
    );
};
export default RenderInactive;
