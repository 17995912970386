import React from 'react';
import loadable from '@loadable/component';
import SniffButton from '../../components/sniff-button';
import { RESERVATION_TYPE } from '../../consts/reservation.consts';
import SvgStarSolid from 'src/assets/svg/SvgStarSolid.svg';
import { showPrice } from '../../helpers/price';

const RenderInactive = loadable(() => import('./render-inactive'));

const DesktopBookBlock = ({ spot, handleBook, useState }) => {
    const isForeign = spot?.country !== 'United States';
    const parsedPrice = showPrice(spot.price, 0, isForeign);

    return (
        <div>
            {spot.live && spot.host?.id > 0 ? (
                <>
                    <p className="snif-m2 snif-medium mb-2">Book this spot!</p>
                    <div className="book-price mb-3">
                        <div>
                            <span className="price snif-m1 snif-semibold">{parsedPrice} </span>
                            per dog per hour
                        </div>
                        <div className="book-rating">
                            {spot.rating ? (
                                <>
                                    <SvgStarSolid className="star-icon" />
                                    <div className="ratings-count">{spot.rating.toPrecision(2)}</div>
                                    <div className="comments-count">{`(${spot.reviewsCount})`}</div>
                                </>
                            ) : (
                                <span className="new">New!</span>
                            )}
                        </div>
                    </div>
                    <SniffButton className="mb-2" size="lg" block onClick={() => handleBook(RESERVATION_TYPE.BOOK)}>
                        Book now
                    </SniffButton>
                    <SniffButton
                        color="secondary"
                        size="lg"
                        block
                        disabled={!spot.live || spot.host?.id == 0}
                        onClick={() => handleBook(RESERVATION_TYPE.CALENDAR)}
                    >
                        Find an available time
                    </SniffButton>
                </>
            ) : (
                <RenderInactive {...{ spot, useState }} />
            )}
        </div>
    );
};
export default DesktopBookBlock;
