export const AUTH_TESTS = {
    MOBILE_PHOTO_GALLERY: 'MOBILE_PHOTO_GALLERY',
    DESKTOP_PHOTO_GALLERY: 'DESKTOP_PHOTO_GALLERY',
    DIST_PRIVATE: 'DIST_PRIVATE',
    ALL_AMENITIES: 'ALL_AMENITIES',
    ALL_EXTRAS: 'ALL_EXTRAS',
    ALL_REVIEWS: 'ALL_REVIEWS',
    MORE_REVIEWS: 'MORE_REVIEWS',
    GUEST_REVIEW_PHOTO: 'GUEST_REVIEW_PHOTO',
};
