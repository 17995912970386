import React from 'react';
import RenderSpotMembership from './render-spot-membership';
import RenderSpotSniffpass from './render-spot-sniffpass';
import SpotTopBadge from '../../components/spot-top-badge';
import SvgStarSolid from 'src/assets/svg/SvgStarSolid.svg';

const RenderSubInfo = ({ spot, handleGoTo, handleSniffpassBadge, handleMembershipBadge }) => {
    const reviewsText =
        spot.reviewsCount > 0 ? `${spot.reviewsCount} review${spot.reviewsCount > 1 ? 's' : ''}` : 'No reviews yet';

    return (
        <div className="sdp-render-sub-info">
            <div className="mobile-sub-info">
                {spot.rating !== null && (
                    <>
                        <SvgStarSolid className="sub-star" />
                        <span className="sub-rating">{spot.rating?.toPrecision(2)}</span>
                        <span className="dot-separator" />
                    </>
                )}
                <span className="sub-reviews" onClick={handleGoTo}>
                    {reviewsText}
                    <div className="dotted-underline" />
                </span>
            </div>
            <div className="mobile-sub-badges">
                <SpotTopBadge spot={spot} />
                <div className="d-flex d-md-none">
                    {spot.offerSniffpass && <RenderSpotSniffpass {...{ handleSniffpassBadge }} />}
                    {spot.offerMembership && spot.activeMembership && (
                        <RenderSpotMembership {...{ handleMembershipBadge }} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default RenderSubInfo;
