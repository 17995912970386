import React from 'react';
import SvgHand from 'src/assets/svg/SvgHand.svg';
import SvgStarEmpty from 'src/assets/svg/SvgStarEmpty.svg';
import SvgTopBadge from 'src/assets/svg/SvgTopBadge.svg';
import './render-spot-badge.scss';

const options = {
    top: {
        icon: SvgTopBadge,
        title: 'Top spot!',
        desc: 'This spot consistently provides amazing experiences to guests',
        color: '#3AA648',
        bg: '#E8FFE8',
    },
    new: {
        icon: SvgHand,
        title: 'New spot',
        desc: 'This spot was published recently.\nBe among the first to try it!',
        color: '#4F96C4',
        bg: '#EBF9FC',
    },
    rising: {
        icon: SvgStarEmpty,
        title: 'Rising star',
        desc: 'This is one of our most popular new spots',
        color: '#D7860E',
        bg: '#FFF5E7',
    },
};

const RenderSpotBadge = ({ spot, ...rest }) => {
    const canShow = spot?.top || spot?.new || spot?.risingStar;

    if (!canShow) {
        return '';
    }

    const myBadge = spot?.top ? options.top : spot?.new ? options.new : options.rising;

    return (
        <div className="spot-detail-my-badge" style={{ backgroundColor: myBadge.bg }} {...rest}>
            <myBadge.icon style={{ color: myBadge.color }} />
            <div className="snif-p">
                <p className="snif-semibold mb-1" style={{ color: myBadge.color }}>
                    {myBadge.title}
                </p>
                <p>{myBadge.desc}</p>
            </div>
        </div>
    );
};

export default RenderSpotBadge;
