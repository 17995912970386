import React, { useEffect, useState } from 'react';
import { Collapse as CollapseClient } from 'reactstrap';
import { SPOT_ENCLOSURE, SPOT_FENCE_HEIGHT } from '../../consts/spot.consts';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import SvgAnaliticsBar from 'src/assets/svg/SvgAnaliticsBar.svg';
import SvgChevronDown from 'src/assets/svg/SvgChevronDown.svg';
import SvgChevronUp from 'src/assets/svg/SvgChevronUp.svg';
import SvgFenced from 'src/assets/svg/SvgFenced.svg';
import SvgRule from 'src/assets/svg/SvgRule.svg';
import './index.scss';

const ENCLOSURE_TYPE = {
    FULLY_FENCED: 'FULLY_FENCED',
    PARTIALLY_FENCED: 'PARTIALLY_FENCED',
    UNFENCED: 'UNFENCED',
};

const FENCING_TYPES_FOR_EDIT = [
    {
        value: 'WOOD_PRIVACY',
        label: 'Wood - privacy',
    },
    {
        value: 'WOOD_NON_PRIVACY',
        label: 'Wood - non-privacy',
    },
    {
        value: 'CHAIN_LINK_PRIVACY',
        label: 'Chain link - privacy',
    },
    {
        value: 'CHAIN_LINK_NON_PRIVACY',
        label: 'Chain link - non-privacy',
    },
    {
        value: 'CATTLE_WITH_MESH',
        label: 'Cattle with wire mesh',
    },
    {
        value: 'CATTLE_WITHOUT_MESH',
        label: 'Cattle without wire mesh',
    },
];

const Div = ({ children, ...rest }) => <div {...{ rest }}>{children}</div>;

const Enclosure = ({ encls }) =>
    encls && (
        <div className="enclosure">
            <SvgFenced />
            <div>
                <p className="snif-p mb-1 snif-semibold">Enclosure</p>
                <p className="snif-p mb-3 deep-gray">{SPOT_ENCLOSURE[encls.toUpperCase()]}</p>
            </div>
        </div>
    );

const Fencing = ({ fncHeight }) =>
    fncHeight && (
        <div className="fence-height">
            <SvgRule />
            <div>
                <p className="snif-p mb-1 snif-semibold">Fencing height (at lowest point)</p>
                <p className="snif-p mb-3 deep-gray">{`${SPOT_FENCE_HEIGHT[fncHeight.toUpperCase()]} `}</p>
            </div>
        </div>
    );

const FencingPercent = ({ isMobile, fencingPercentage }) => {
    const [SvgSize, setSvgSize] = useState(16);

    useEffect(() => {
        if (!isMobile) {
            setSvgSize(24);
        }
    }, []);

    if (fencingPercentage == null) {
        return '';
    }

    return (
        <p className="percentage mb-1">
            <SvgAnaliticsBar width={SvgSize} height={SvgSize} className="icon" />
            <span className="snif-semibold">{fencingPercentage}% of guests</span> said fencing at this spot was secure
            enough to contain their dogs
        </p>
    );
};

const Gaps = ({ gaps }) => (
    <div className="fence-gap">
        <p className="snif-s1 snif-semibold">
            Gaps or holes in fence: <span className="snif-regular">{gaps ? 'Gaps' : 'No gaps'}</span>
        </p>
    </div>
);

const FencingTypes = ({ fencingType }) => {
    return fencingType && fencingType.length > 0 ? (
        <div className="fence-type mt-1">
            <p className="snif-s1 snif-semibold">
                Fencing type(s):{' '}
                <span className="snif-regular">
                    {fencingType.map((f, i) => {
                        const predef = FENCING_TYPES_FOR_EDIT.find((t) => t.value === f);
                        const type = predef ? predef.label : f;
                        return `${type}${fencingType.length - 1 !== i ? ', ' : ''}`;
                    })}
                </span>
            </p>
        </div>
    ) : null;
};

const FencingDetail = ({ fenceOpen, fencingDetail, setFenceOpen, Collapse }) => {
    if (!fencingDetail) {
        return '';
    }

    const isLong = fencingDetail.length > 240;
    const firstDesc = fencingDetail.substring(0, 240);

    return (
        <div className="fence-detail break-word mt-1">
            <p className="snif-s1 snif-semibold">
                <span>Fencing details: </span>
                <span className="snif-regular text-pre">
                    {isLong ? (
                        <>
                            <Collapse isOpen={fenceOpen}>
                                {fenceOpen ? fencingDetail : firstDesc.padEnd(243, '.')}
                            </Collapse>
                            <div
                                onClick={() => setFenceOpen(!fenceOpen)}
                                className="read-more snif-semibold text-dark mt-2 mb-2"
                            >
                                {fenceOpen ? 'Show less details' : 'Show more details'}
                                {fenceOpen ? (
                                    <SvgChevronUp width={14} className="ms-1 text-dark" />
                                ) : (
                                    <SvgChevronDown width={14} className="ms-1 text-dark" />
                                )}
                            </div>
                        </>
                    ) : (
                        fencingDetail
                    )}
                </span>
            </p>
        </div>
    );
};

const ListingFencing = ({
    isMobileSSR,
    useStateSSR,
    fncHeight,
    encls,
    gaps,
    fencingType,
    fencingDetail,
    fencingPercentage,
}) => {
    const Collapse = useStateSSR ? Div : CollapseClient;
    const useStateC = useStateSSR || useState;
    const isMobile = useStateSSR ? isMobileSSR : useMobileScreen();
    const [fenceOpen, setFenceOpen] = useStateC(false);
    const haveInfo = encls || fncHeight || gaps || fencingType;
    const isFullyFenced = encls === ENCLOSURE_TYPE.FULLY_FENCED;

    if (!haveInfo || !encls) {
        return '';
    }

    return (
        <div className="fencing px-2 px-md-0">
            <div className="snif-m1 snif-semibold mt-1 mb-3">Fencing</div>
            <div className="fence-details">
                <div>
                    <Enclosure {...{ encls }} />
                    {isFullyFenced && <Fencing {...{ fncHeight }} />}
                </div>
                <div>
                    {isFullyFenced && (
                        <>
                            <FencingPercent {...{ isMobile, fencingPercentage }} />
                            <Gaps {...{ gaps }} />
                            <FencingTypes {...{ fencingType }} />
                        </>
                    )}
                    <FencingDetail {...{ fenceOpen, fencingDetail, setFenceOpen, Collapse }} />
                </div>
            </div>
            <hr />
        </div>
    );
};

export default ListingFencing;
