import { RESERVATION_STATUS } from '../consts/reservation.consts';
import moment from 'moment-timezone';

export function getReservationStatusInfo({ status, startDate: startDateProp, endDate: endDateProp }) {
    let text;
    let mod;

    const startDate = new Date(startDateProp);
    const endDate = new Date(endDateProp);

    switch (status) {
        case RESERVATION_STATUS.CONFIRMED: {
            if (moment().isAfter(moment(endDate))) {
                text = 'Past';
                mod = RESERVATION_STATUS.CALCULATED.PAST;
            } else if (moment(startDate).isAfter(moment())) {
                text = 'Upcoming';
                mod = RESERVATION_STATUS.CALCULATED.UPCOMING;
            } else if (moment(endDate).isAfter(moment()) && moment().isAfter(moment(startDate))) {
                text = 'Active';
                mod = RESERVATION_STATUS.CALCULATED.ACTIVE;
            }
            break;
        }

        case RESERVATION_STATUS.CANCELLED_BY.ADMIN:
            text = 'Canceled by admin';
            mod = RESERVATION_STATUS.CANCELLED_BY;
            break;
        case RESERVATION_STATUS.CANCELLED_BY.HOST:
            text = 'Declined';
            mod = RESERVATION_STATUS.CANCELLED_BY.HOST;
            break;
        case RESERVATION_STATUS.CANCELLED_BY.GUEST:
            text = 'Canceled';
            mod = RESERVATION_STATUS.CANCELLED_BY.GUEST;
            break;
        case RESERVATION_STATUS.CANCELLED_BY.TIMEOUT:
            text = 'Expired';
            mod = RESERVATION_STATUS.CANCELLED_BY.TIMEOUT;
            break;
        case RESERVATION_STATUS.WAITING:
            text = 'Pending';
            mod = RESERVATION_STATUS.WAITING;
            break;
    }

    return {
        text,
        mod,
    };
}

export function formatDogsCount(count) {
    return `${count} dog${count > 1 ? 's' : ''}`;
}

export function getEndDate({ date, length }) {
    return moment(date).add(length * 60, 'minutes');
}

export function dateToString(date) {
    if (date && typeof date.getMonth === 'function') {
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${date.getFullYear()}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    } else {
        return date;
    }
}

export function sortByObjTitle(a, b) {
    var titleA = a.title.toUpperCase();
    var titleB = b.title.toUpperCase();
    if (titleA < titleB) {
        return -1;
    }
    if (titleA > titleB) {
        return 1;
    }
    return 0;
}
