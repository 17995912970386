import React from 'react';
import SvgSubscription from 'src/assets/svg/SvgSubscription.svg';
import './render-spot-membership.scss';

const RenderSpotSniffpass = ({ handleSniffpassBadge }) => {
    return (
        <div className="spot-badge-offer-membership" onClick={handleSniffpassBadge}>
            <SvgSubscription />
            <span>Sniffpass</span>
        </div>
    );
};

export default RenderSpotSniffpass;
