import React from 'react';
import SvgCalendarBlock from 'src/assets/svg/SvgCalendarBlock.svg';

const RenderMobileInactive = ({ spot, isSmartBannerShow }) => {
    return (
        <div className={`dis-info top-bar ${isSmartBannerShow ? 'banner' : ''}`}>
            <span className="dis-cal-ico">
                <SvgCalendarBlock />
            </span>
            <div className="dis-text">
                <p className="title snif-semibold mb-0">
                    {spot.turnBackOnAt ? 'This spot is taking a break' : 'This spot is not available'}
                </p>
            </div>
        </div>
    );
};

export default RenderMobileInactive;
