import React from 'react';
import { showSize } from '../../helpers/common';
import SvgRuler from 'src/assets/svg/SvgRuler.svg';

export const Size = ({ size, sizeUnits }) => {
    return size ? (
        <div className="head-info-box">
            <SvgRuler className="head-ico ruler" />
            <span className="snif-s1">{showSize(size, sizeUnits)}</span>
        </div>
    ) : null;
};
