import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import CookieService from '../../services/cookie.service';

const SF_FENCE_OPTIONS = [
    {
        value: undefined,
        label: 'Any height',
    },
    {
        value: 'FT_3',
        label: '3+ ft',
    },
    {
        value: 'FT_4',
        label: '4+ ft',
    },
    {
        value: 'FT_5',
        label: '5+ ft',
    },
    {
        value: 'FT_6_PLUS',
        label: '6+ ft',
    },
];

export const getSearchFiltersForServer = (filters) => {
    if (!filters) {
        return {};
    }

    let tmp = {};
    tmp.availableDates = filters.availableDates || undefined;
    tmp.availableTimes = filters.availableTimes || undefined;
    tmp.enclosureType = filters.enclosureType || undefined;
    tmp.fenceHeight = filters.fencing || SF_FENCE_OPTIONS[0].value || undefined;
    tmp.minimumSize = filters.minimumSize || undefined;
    tmp.distance = filters.distance || undefined;
    tmp.dogsPresent = filters.dogsPresent ? 'NO' : undefined;
    tmp.peoplePresent = filters.peoplePresent ? 'NO' : undefined;
    tmp.domesticAnimalsPresent = filters.domesticAnimalsPresent ? 'NO' : undefined;
    tmp.topSpots = filters.topSpots || undefined;
    tmp.newSpots = filters.newSpots || undefined;
    tmp.cleanerSpots = filters.cleanerSpots || undefined;
    tmp.amenities = filters.amenities?.length > 0 ? filters.amenities : undefined;
    tmp.maximumDogsAllowed = filters.maximumDogsAllowed || undefined;
    tmp.minimumPrice = filters.minimumPrice || undefined;
    tmp.maximumPrice = filters.maximumPrice || undefined;
    tmp.sortBy = filters.sortBy || undefined;

    return tmp;
};

export const getTopBarProps = (center) => {
    try {
        const searchFilters = JSON.parse(CookieService.get(COOKIE_PARAM_NAME.SEARCH_FILTERS));
        const availabilityFilters = JSON.parse(CookieService.get(COOKIE_PARAM_NAME.SEARCH_FILTERS_AVAILABILITY));
        const addressFilters = JSON.parse(CookieService.get(COOKIE_PARAM_NAME.SEARCH_FILTERS_LOCATION));

        if (searchFilters === null && availabilityFilters === null) {
            return { center };
        } else {
            const filters = { ...searchFilters?.filters, ...availabilityFilters, ...addressFilters };
            return {
                filters: getSearchFiltersForServer(filters),
                center,
            };
        }
    } catch (err) {
        return undefined;
    }
};
