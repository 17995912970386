import { Component } from 'react';
import moment from 'moment-timezone';

class ReservationTimeCell extends Component {
    render() {
        const {
            date,
            length,
            spot: { timezone },
        } = this.props;
        const endDate = moment(date).tz(timezone).add(length, 'hour');
        return `${moment(date).tz(timezone).format('h:mma')} - ${endDate.format('h:mma z')}`;
    }
}

export default ReservationTimeCell;
