import React from 'react';
import { IMG_RESIZES } from '../../consts/resizer.consts';
import { ImageHelper } from '../../classes/image-helper.class';
import PictureSet from '../../components/picture-set';

const RenderHostInfo = ({ spot, handleGoTo, useState }) => {
    const hostAvatar = spot.host?.avatarAws ? ImageHelper.getUrlFromOptions(spot.host?.avatarAws, `full-fit-in/${IMG_RESIZES.x64}`) : '';

    return (
        <div className="mobile-host-info">
            <PictureSet imgs={hostAvatar} alt="Host avatar" className="host-avatar" isAvatar SSR={!!useState} useState={useState} />
            <div className="host-text">
                {'Hosted by '}
                <span className="host-name" onClick={handleGoTo}>
                    {spot.host?.nameAndInitial}
                    <div className="dotted-underline" />
                </span>
            </div>
        </div>
    );
};

export default RenderHostInfo;
