import React from 'react';
import { Size } from './size';
import { Enclosure } from './enclosure';
import { showDistanceFromYou } from '../../helpers/spot';
import SvgLocation from 'src/assets/svg/SvgLocation.svg';
import './index.scss';

const ListingHead = ({ title, fncd, size, sizeUnits, city, state, distanceFrom, userDetails }) => {
    return (
        <section id="overview">
            <div className="row head-row-title">
                <div className="col-md-12">
                    <h1 className="head-title snif-m1 snif-xl-pc">{title}</h1>
                </div>
            </div>
            <div className="row snif-p head-location">
                <div className="col-md-12">
                    <SvgLocation />
                    {city ? <span>{city}</span> : null}
                    {state ? <span>, {state}</span> : null}
                    {showDistanceFromYou(distanceFrom, userDetails)}
                </div>
            </div>
            <div className="row head-info">
                <div className="col-md-12">
                    <Enclosure fncd={fncd} />
                    <Size size={size} sizeUnits={sizeUnits} />
                </div>
            </div>
        </section>
    );
};

export default ListingHead;
