import { Component } from 'react';
import moment from 'moment-timezone';

class ReservationDateCell extends Component {
    render() {
        const { date } = this.props;
        return moment(date).format('dddd, MMMM D');
    }
}

export default ReservationDateCell;
