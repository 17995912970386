import React from 'react';
import SvgMembership from 'src/assets/svg/SvgMembership.svg';
import './render-spot-membership.scss';

const RenderSpotMembership = ({ handleMembershipBadge }) => {
    return (
        <div className="spot-badge-offer-membership" onClick={handleMembershipBadge}>
            <SvgMembership />
            <span>Membership</span>
        </div>
    );
};

export default RenderSpotMembership;
