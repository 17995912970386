import React, { Component } from 'react';
import bemCn from 'bem-cn';
import './style.scss';

const b = bemCn('table-header-cell');

class TableHeaderCell extends Component {
    render() {
        const { className, icon, title, ...rest } = this.props;
        return (
            <div className={b.mix(['details-1', className])} {...rest}>
                <div className={b('info')}>
                    <div className={b('icon')}>{icon}</div>
                    <div className={b('title')}>{title}</div>
                </div>
            </div>
        );
    }
}

export default TableHeaderCell;
