import React from 'react';
import { RouteFormatter } from '../../routes';
import SvgChevronRight from 'src/assets/svg/SvgChevronRight.svg';
import SvgChevronLeft from 'src/assets/svg/SvgChevronLeft.svg';
import './render-spot-next.scss';

const RenderSpotNext = ({ topBarData, canPrev, showNav, isTopBarSignup }) => {
    const prev = topBarData?.prevSpot?.spotUrl;
    const next = topBarData?.nextSpot?.spotUrl;
    const isChained = prev && canPrev;

    const prevUrl = isChained ? RouteFormatter.listings({ first: prev }) : RouteFormatter.listings({});
    const nextUrl = RouteFormatter.listings({ first: next });

    const prevClick = (e) => !isChained && e.preventDefault();
    const nextClick = (e) => !next && e.preventDefault();

    return (
        <div className={`spot-next-prev-container ${showNav || showNav == undefined ? '' : 'full-top'} ${isTopBarSignup ? 'is-top-bar-signup' : ''}`}>
            <div className="spot-next-prev">
                <a href={prevUrl} className={!isChained ? 'disabled' : ''} onClick={prevClick}>
                    <SvgChevronLeft />
                    <span>PREV SPOT</span>
                </a>
                <a href={nextUrl} className={!next ? 'disabled' : ''} onClick={nextClick}>
                    <span>NEXT SPOT</span>
                    <SvgChevronRight />
                </a>
            </div>
        </div>
    );
};

export default RenderSpotNext;
