import React, { useState as useStateClient } from 'react';
import { RouteFormatter } from '../../routes';
import ModalMobileClient from '../modal-mobile';
import ModalMobileSSR from '../modal-mobile/modal-mobile-ssr';
import SniffButton from '../sniff-button';
import SvgInfo from 'src/assets/svg/SvgInfo.svg';
import './index.scss';

const ListingInactiveInfo = ({ spot, useState: useStateSSR }) => {
    const useState = useStateSSR || useStateClient;
    const ModalMobile = useStateSSR ? ModalMobileSSR : ModalMobileClient;

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    const handleExplore = () => {
        toggle();
        window.location = RouteFormatter.listings({});
    };

    return (
        <div className="dis-info-ico">
            <SvgInfo onClick={toggle} />
            <ModalMobile title="Inactive spot" isOpen={isOpen} toggle={toggle}>
                {spot.turnOffReason && (
                    <>
                        <p className="snif-p snif-semibold mb-2">The host provided additional info:</p>
                        <p className="snif-p mb-4">"{spot.turnOffReason}"</p>
                    </>
                )}
                <SniffButton color="secondary" size="lg" onClick={handleExplore} block="mobile">
                    Explore other spots
                </SniffButton>
            </ModalMobile>
        </div>
    );
};

export default ListingInactiveInfo;
