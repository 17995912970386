import React from 'react';
import { SPOT_ENCLOSURE } from '../../consts/spot.consts';
import SvgFenced from 'src/assets/svg/SvgFenced.svg';

export const Enclosure = ({ fncd }) => {
    return fncd ? (
        <div className="head-info-box">
            <SvgFenced className="head-ico fenced" />
            <span className="enc-type snif-s1">{SPOT_ENCLOSURE[fncd.toUpperCase()]}</span>
        </div>
    ) : null;
};
